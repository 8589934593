// store/slices/productSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProducts } from 'services/api';
import { signOut } from './authSlice';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const response = await getProducts();
  return response;
});


const productSlice = createSlice({
  name: 'products',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  
  reducers: {
    reset: (state) => {
      return {
        items: [],
        loading: false,
        error: null,
      };
    },
  },
    extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(signOut.fulfilled, (state) => {
        return {
          items: [],
          loading: false,
          error: null,
        };
      });
  },
});

// Export the reset action
export const { reset } = productSlice.actions;

// Export the reducer as the default export
export default productSlice.reducer;