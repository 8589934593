import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import { getUserInfo } from 'services/api';
import productReducer, { fetchProducts } from './productSlice';
import { fetchAlexaProducts } from './alexaSlice';
import { fetchOnePageProducts } from './onePageSlice';

import { injectReducer } from 'store'; 

let sessionExpiredNotificationShown = false;

export const resetSessionNotificationFlag = () => {
	sessionExpiredNotificationShown = false;
  };

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: '',
	user: null
}

export const signIn = createAsyncThunk('auth/signIn', async ({ email, username, password, isAdmin = false }, thunkAPI) => {
	const { dispatch, rejectWithValue } = thunkAPI;
	
	try {
		const response = isAdmin 
			? await AuthService.adminLogin({ username, password })
			: await AuthService.login({ email, password });
		const token = response.token;

		if (!token) throw new Error('Token not found in response');

		localStorage.setItem(AUTH_TOKEN, token);

		if (isAdmin) {
			const { username, role, title, name, email} = response;  
            dispatch(signInSuccess({ token, user: { username, role, title, email } }));
            return { token, user: { username, name, role, title, email }, redirect: '/admin/dashboard' };
		}

		const userInfo = await getUserInfo();

		injectReducer('products', productReducer);
		await dispatch(fetchProducts());
		await dispatch(fetchAlexaProducts());
		await dispatch(fetchOnePageProducts());


		dispatch(signInSuccess({ token, user: userInfo }));
		return { token, user: userInfo };
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Login failed');
	}
});

export const signOut = createAsyncThunk('auth/signOut', async (_, { dispatch }) => {
    try {
        await AuthService.logout();

        return {}; 
    } catch (error) {
        console.error("Error during sign-out:", error);
        throw error;
    }
});


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = '/dashboard';
			state.token = action.payload.token;
			state.user = action.payload.user;
			state.showMessage = false;
			state.message = '';	
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state, action) => {
			state.loading = false;
			state.token = null;
			resetSessionNotificationFlag(); 
			state.redirect = action.payload?.isAdminArea ? '/admin' : '/login';
			state.user = null;
		},
		showLoading: (state, action) => {
			state.loading = action.payload;
		  },		  
		signInSuccess: (state, action) => {
			state.loading = false;
			state.redirect = '/dashboard';
			state.token = action.payload.token;
			state.user = action.payload.user;
			state.showMessage = false;
			state.message = '';	
			console.log("Signin success reducer", action.payload);
		},
		clearRedirect: (state) => {
            state.redirect = null;
        }
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				console.log("signIn fulfilled with payload:", action.payload); // Log the full payload
				console.log("User information being set in state:", action.payload.user); // Log user info
			
				state.loading = false;
				state.redirect = '/dashboard';
				state.token = action.payload.token;
				state.user = action.payload.user;
				state.showMessage = false;
        		state.message = '';				
				console.log("State after setting user:", state.user); // Verify user is stored in state
			})
			
			.addCase(signIn.rejected, (state, action) => {
				console.log("signIn rejected with error:", action.payload); 
				state.loading = false;
				state.message = action.payload;
				state.showMessage = true;
				state.token = null;
				state.user = null;
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false;
				state.token = null;
				state.user = null;
				state.redirect = '/';
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	clearRedirect
} = authSlice.actions

export default authSlice.reducer