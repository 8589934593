// store/slices/onePageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOnePages } from 'services/api';
import { signOut } from './authSlice'; 


export const fetchOnePageProducts = createAsyncThunk('onePage/fetchOnePageProducts', async () => {
  const response = await getOnePages();
  return response;  
});

const onePageSlice = createSlice({
  name: 'onePage',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset: (state) => {
      return {
        items: [],
        loading: false,
        error: null,
      };
    },
  },  extraReducers: (builder) => {
    builder
      .addCase(fetchOnePageProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOnePageProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchOnePageProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(signOut.fulfilled, (state) => {
        return {
          items: [],
          loading: false,
          error: null,
        };
      });
  },
});

// Export the reset action
export const { reset } = onePageSlice.actions;

export default onePageSlice.reducer;
